
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'
import type { ElForm } from "element-plus"

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const member = ref({} as any)

    const onCancel = () => {
      router.push({ name: "member" })
    }

    const isNew = !route.params.id

    const formRef = ref<FormInstance>()

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    })

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          if (isNew) {
            store
              .dispatch(
                Actions.CREATE_ADMIN,
                member.value
              )
              .then(() => {
                router.push({ name: "member" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_ADMIN, {
                id: route.params.id,
                values: member.value,
              })
              .then(() => {
                router.push({ name: "member" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          }
        } else {
          return false;
        }
      })
    }

    if (!isNew) {
      store
        .dispatch(Actions.GET_ADMIN, route.params.id)
        .then(() => {
          member.value = store.getters.currentAdmin;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const roles = ref([
      {
        label: "管理者", value: 'admin'
      },
      {
        label: "编辑者", value: 'editor'
      },
      {
        label: "审核者", value: 'approver'
      },
    ])

    return {
      member,
      rules,
      onSubmit,
      onCancel,
      roles,
      formRef
    }
  }
})
